<template>
  <div>
    <input
      v-model="value"
      type="text"
      :placeholder="placeholder"
      class="border parula__border-black w-full p-1"
      @keypress="toNumberOnly"
      @change="deleteLastDot"
    />

    <div class="flex justify-end text-sm">
      Raw: {{ computedWeiValue }} / Decimals: {{ decimals }}
    </div>
  </div>
</template>

<script>
import BigNumber from "bignumber.js";

export default {
  props: {
    modelValue: String,
    placeholder: String,
    decimals: {
      type: Number,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      value: "",
      weiValue: "",
    };
  },

  computed: {
    computedWeiValue: {
      get() {
        return this.weiValue;
      },
      set(value) {
        if (value === "") {
          this.initWeiValue();
          this.$emit("update:modelValue", "");
          return;
        }

        BigNumber.config({
          DECIMAL_PLACES: this.decimals,
        });

        const bigNumberValue = new BigNumber(value);
        const weiValue = bigNumberValue
          .multipliedBy(10 ** this.decimals)
          .toFixed();

        this.weiValue = weiValue;
        this.$emit("update:modelValue", weiValue);
        // this.$emit("update:modelValue", value);
      },
    },
  },

  watch: {
    value: function() {
      // 맨 첫 자리에 '.' 들어오면 삭제
      if (this.value.startsWith(".")) this.value = "";

      if (this.value.indexOf(".") !== -1) {
        // '.' 중복이면 삭제
        if (this.value.indexOf(".") !== this.value.lastIndexOf("."))
          this.value = this.value.substring(0, this.value.lastIndexOf("."));

        // decimal보다 소숫점 이하 자릿수가 크면 맞추기
        const splitValue = this.value.split(".");

        if (splitValue[1].length > this.decimals) {
          this.value = `${splitValue[0]}.${splitValue[1].substring(
            0,
            this.decimals
          )}`;
        }
      }

      this.computedWeiValue = this.value;
    },
  },

  created() {
    this.initWeiValue();
  },

  methods: {
    toNumberOnly(e) {
      let { key } = e;
      key = key.replace(/[^0-9.]/g, "");

      if (!key) {
        e.preventDefault();
      } else return true;

      // e = e ? e : window.event;
      // const charCode = e.which ? e.which : e.keyCode;

      // if (
      //   charCode > 31 &&
      //   (charCode < 48 || charCode > 57) &&
      //   charCode !== 46
      // ) {
      //   e.preventDefault();
      // } else {
      //   return true;
      // }
    },

    deleteLastDot() {
      if (this.value.endsWith("."))
        this.value = this.value.substring(0, this.value.lastIndexOf("."));
    },

    initWeiValue() {
      this.weiValue = "0".repeat(this.decimals);
    },
  },
};
</script>
