<template>
  <div>
    <input
      id="hashtagList"
      type="text"
      class="border parula__border-black w-full p-1"
      placeholder="Enter the hashtag in your work and click to delete"
      v-model="hashtag"
      @keyup.enter.prevent="addTag"
      @keyup.delete="deleteTagByKeyup"
      @change="addTag"
    />

    <div>
      <button
        v-for="(item, index) in hashtagList"
        :key="index"
        type="button"
        class="border parula__border-black text-xs p-1 mt-1 mr-1"
        @click="deleteTagByClick(index)"
      >
        {{ item }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue"],

  data() {
    return {
      hashtag: "",
      hashtagList: [],
    };
  },

  methods: {
    addTag: function() {
      const hashtag = this.hashtag.replace(/(\s*)/g, "");

      if (!this.hashtag) return;

      hashtag.startsWith("#")
        ? (this.hashtag = hashtag)
        : (this.hashtag = `#${hashtag}`);

      this.hashtagList.push(this.hashtag);
      this.hashtag = "";

      this.$emit("update:modelValue", this.hashtagList);
    },

    deleteTagByKeyup: function() {
      const length = this.hashtagList.length;

      if (this.hashtag) return;
      if (!length) return;

      this.hashtagList.splice(length - 1, 1);

      this.$emit("update:modelValue", this.hashtagList);
    },

    deleteTagByClick: function(index) {
      this.hashtagList.splice(index, 1);

      this.$emit("update:modelValue", this.hashtagList);
    },

    init() {
      this.hashtagList = [];
    },
  },
};
</script>

<style scoped>
button:hover {
  background-color: #262626;
  color: white;
}

::placeholder {
  font-size: 1rem;
  color: #8a8a8a;
}
</style>
