<template>
  <div class="form__adjustment">
    <!-- <select
      name="sales"
      id="sales"
      v-model="orderType"
      class="font-semibold md:text-lg lg:text-xl mb-10"
    >
      <option value="normal">Normal Sale</option>
      <option value="auction">Auction Sale</option>
    </select> -->

    <h1 class="font-semibold md:text-lg lg:text-xl mb-10">
      {{ orderType === "normal" ? "Normal Sale" : "Auction Sale" }}
    </h1>

    <section class="relative">
      <div class="flex flex-col gap-y-2 mb-7">
        <div class="flex">
          <label for="title" class="w-1/4">Artwork</label>
          <div
            class="cursor-pointer border parula__border-black w-3/4 h-64"
            @click="productChoice = true"
          >
            <content-viewer
              v-if="product && product.image"
              :filePath="product.image"
              object-fit="object-contain"
            />
            <!-- <img
              v-if="product && product.image"
              :src="product.image"
              alt="image"
              class="object-contain w-full h-full"
            /> -->
            <div v-else class="flex justify-center items-center w-full h-full">
              <span class="parula__text-gray">Select NFT Artwork</span>
            </div>
          </div>
        </div>

        <div class="flex">
          <label for="title" class="w-1/4">Thumbnail</label>
          <div class="border parula__border-black w-3/4 h-64">
            <content-viewer
              v-if="product && product.thumbnail"
              :filePath="product.thumbnail"
              object-fit="object-contain"
            />
            <!-- <img
              v-if="product && product.thumbnail"
              :src="product.thumbnail"
              alt="thumbnail"
              class="object-contain w-full h-full"
            /> -->
            <div v-else class="flex justify-center items-center w-full h-full">
              <!-- <span class="parula__text-gray">Thumbnail</span> -->
            </div>
          </div>
        </div>
      </div>

      <!-- product-search modal -->
      <div v-if="productChoice" class="flex justify-center">
        <div
          v-click-outside="closeProductSelector"
          class="absolute top-0 right-0 w-3/4"
        >
          <product-selector
            :order-type="orderType"
            @selected-product="onSelectProduct"
            @close-product-selector="closeProductSelector"
          />
        </div>
      </div>

      <div>
        <h2 class="text-lg font-semibold my-8">Information</h2>
        <div class="flex flex-col gap-y-2">
          <div class="flex">
            <label for="title" class="w-1/4">Title</label>
            <input
              id="title"
              v-model="product.name"
              placeholder="Title"
              type="text"
              class="
                cursor-default
                focus:outline-none
                border
                parula__border-black parula__bg-gray-soft
                w-3/4
                p-1
              "
              readonly
            />
          </div>

          <div class="flex">
            <label for="artist" class="w-1/4">Artist</label>
            <input
              v-model="artist_penname"
              placeholder="Artist"
              type="text"
              class="
                cursor-default
                focus:outline-none
                border
                parula__border-black parula__bg-gray-soft
                w-3/4
                p-1
              "
              readonly
            />
          </div>

          <div class="flex">
            <label for="description" class="w-1/4">Description</label>
            <textarea
              id="description"
              v-model="description"
              placeholder="Description"
              type="text"
              rows="5"
              class="
                block
                cursor-default
                focus:outline-none
                border
                parula__border-black parula__bg-gray-soft
                w-3/4
                p-1
              "
              readonly
            />
          </div>

          <div class="flex">
            <label for="hashtagList" class="w-1/4">Hashtag</label>
            <input-hashtag
              v-model="hashtagList"
              ref="InputHashtag"
              class="w-3/4"
            />
          </div>
        </div>

        <h2 class="text-lg font-semibold my-8">BLOCKCHAIN</h2>
        <div class="flex flex-col gap-y-2">
          <div class="flex">
            <label for="selectedAsset" class="w-1/4">Asset</label>
            <asset-selector
              id="asset_id"
              v-model="asset_id"
              :blockchain_id="product.blockchain_id"
              :category="1"
              :option="'symbol'"
              :order_type="orderType"
              @changeAsset="changeAsset"
              class="focus:outline-none input-design"
            />
          </div>
        </div>

        <h2 class="text-lg font-semibold my-8">PRICE</h2>

        <!-- normal sale -->
        <div v-if="orderType === 'normal'" class="flex flex-col gap-y-2">
          <div class="flex">
            <label for="fixedPrice" class="w-1/4">Fixed Price</label>
            <input
              v-if="!decimals"
              type="text"
              placeholder="Please, Select a asset first."
              class="
                  cursor-default
                  focus:outline-none
                  parula__bg-gray-soft
                  input-design
                  
                "
              disabled
            />
            <input-decimal
              v-else
              v-model="fixedPrice"
              placeholder="Fixed price"
              class="w-3/4"
              :decimals="decimals"
            />
          </div>

          <div
            v-if="product.asset_type === 'ERC1155'"
            class="flex flex-col gap-y-2"
          >
            <!-- 전체 갯수 -->
            <div class="flex">
              <label for="totalSupply" class="w-1/4">Supply</label>
              <input
                id="totalSupply"
                :value="product.total_supply"
                placeholder="Supply"
                type="text"
                class="
                    cursor-default
                    focus:outline-none
                    border
                    parula__border-black parula__bg-gray-soft
                    w-3/4
                    p-1
                  "
                readonly
              />
            </div>

            <!-- quantity -->
            <!-- 올릴 갯수 -->
            <div class="flex">
              <label for="quantity" class="w-1/4">Quantity</label>
              <div class="w-3/4">
                <input
                  id="quantity"
                  v-model="quantity"
                  placeholder="Quantity"
                  type="number"
                  min="0"
                  step="1"
                  class="
                      cursor-default
                      focus:outline-none
                      border
                      parula__border-black
                      w-full
                      p-1
                    "
                />
                <div class="flex justify-between flex-row-reverse mt-0.5">
                  <p class="text-xs text-right mt-1">
                    <!-- 내가 가지고 있는 갯수 -->
                    Balance: {{ balance }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- auction sale -->
        <div v-else-if="orderType === 'auction'" class="flex flex-col gap-y-2">
          <div class="flex">
            <label for="beginPrice" class="w-1/4">Begin Price</label>
            <input
              v-if="!decimals"
              type="text"
              placeholder="Please, Select a asset first."
              class="
                  cursor-default
                  focus:outline-none
                  parula__bg-gray-soft
                  input-design
                "
              disabled
            />
            <input-decimal
              v-else
              v-model="beginPrice"
              placeholder="Begin price"
              :decimals="decimals"
              class="w-3/4"
            />
          </div>

          <div class="flex">
            <label for="buyNowPrice" class="w-1/4">Buy Now Price</label>
            <label class="switch" @click="buyNowPrice = ''">
              <input v-model="useBuyNow" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div v-if="useBuyNow" class="flex justify-end">
            <input
              v-if="!decimals"
              type="text"
              placeholder="Please, Select a asset first."
              class="
                  cursor-default
                  focus:outline-none
                  parula__bg-gray-soft
                  input-design
                "
              disabled
            />
            <input-decimal
              v-else
              v-model="buyNowPrice"
              placeholder="Price to buy it now"
              :decimals="decimals"
              class="w-3/4"
            />
          </div>

          <div class="flex">
            <label for="reservedPrice" class="w-1/4">Reserved Price</label>
            <label class="switch" @click="reservedPrice = ''">
              <input v-model="useReserved" type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div>
          <div v-if="useReserved" class="flex justify-end">
            <input
              v-if="!decimals"
              type="text"
              placeholder="Please, Select a asset first."
              class="
                  cursor-default
                  focus:outline-none
                  parula__bg-gray-soft
                  input-design
                "
              disabled
            />
            <input-decimal
              v-else
              v-model="reservedPrice"
              placeholder="Reserved price"
              :decimals="decimals"
              class="w-3/4"
            />
          </div>

          <div class="flex">
            <label for="date" class="w-1/4">Date</label>
            <date-picker
              locale="en"
              weekStart="0"
              autoApply
              range
              placeholder="Click to change the date"
              monthNameFormat="long"
              :closeOnAutoApply="false"
              :format="(format = 'yyyy/MM/dd HH:mm')"
              v-model="date"
              inputClassName="dp-custom-input"
              class="w-3/4"
            />
          </div>
        </div>

        <div class="flex mt-2">
          <label for="royalties" class="w-1/4">Royalties</label>
          <input
            id="royalties"
            v-model="royalties"
            placeholder="Royalty"
            type="number"
            class="
              cursor-default
              focus:outline-none
              parula__bg-gray-soft
              input-design
            "
            readonly
          />
        </div>

        <!-- <div class="flex items-center">
          <h2 class="text-lg my-8 mr-5">
            Register For Sales Immediately
          </h2>
          <label class="switch">
            <input v-model="orderState" type="checkbox" />
            <span class="slider round"></span>
          </label>
        </div> -->
      </div>
    </section>

    <!-- button -->
    <section class="w-full my-8 flex justify-end gap-x-2">
      <!-- <button
        class="border parula__border-black px-4 py-1 hover__bg--black"
        @click="onCancel"
      >
        Cancel
      </button> -->

      <router-link to="/mypage" class="button__border__green"
        >Cancel
      </router-link>
      <!-- @click="onCreate" -->
      <button class="button__green" @click="onCreate">
        Sell
      </button>
    </section>

    <!-- <result-modal ref="ErrorModal" :msg="'Error Occur'" :modalFunction="init" /> -->

    <result-modal
      ref="ResultModal"
      :msg="'Order has successfully created.'"
      :modalFunction="init"
    />
  </div>

  <notifications position="bottom right" />
</template>

<script>
// import ContentViewer from "@/components/ContentViewer.vue";
import ProductSelector from "@/views/my-page/sub/ProductSelector.vue";
import InputDecimal from "@/views/my-page/sub/InputDecimal.vue";
import InputHashtag from "@/views/my-page/sub/InputHashtag.vue";
import AssetSelector from "@/views/my-page/sub/AssetSelector.vue";
import DatePicker from "@vuepic/vue-datepicker";
import ContentViewer from "@/components/ContentViewer.vue";
import "@vuepic/vue-datepicker/dist/main.css";

import OpenapiAuth from "@/services/openapiAuth.service.js";
import OpenapiService from "@/services/openapi.service.js";
import SessionService from "@/services/session.service";

import BigNumber from "bignumber.js";
import vClickOutside from "click-outside-vue3";
import { notify } from "@kyvg/vue3-notification";
import ResultModal from "@/components/modal/ResultModal.vue";
import { Parula } from "@/parula-js/src/parula.js";
import { weiValueToDB } from "@/util/price.js";
import {
  getCurrentMetamaskAccount,
  getCurrentMetamaskNetworkId,
} from "@/util/wallet.js";

export default {
  data() {
    return {
      userId: "",
      selectedOwnerWallet: "",
      orderType: "",

      artist_penname: "",
      description: "",
      royalties: "",
      fixedPrice: "",

      selectedAsset: {},
      asset_id: "",
      orderState: 1,
      hashtagList: [],

      // erc1155
      quantity: 1,
      balance: 0, // (임시) product choice 했을 때 가져와야 함.

      // auction params
      beginPrice: "",
      buyNowPrice: "",
      reservedPrice: "",
      date: [],
      useBuyNow: false,
      useReserved: false,

      // common
      decimals: "",
      account: "",
      product: {},

      // modal control
      productChoice: false,
    };
  },

  components: {
    ContentViewer,
    ProductSelector,
    InputDecimal,
    InputHashtag,
    AssetSelector,
    ResultModal,
    DatePicker,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  async created() {
    // console.log(this.$route.query);
    const { orderType } = this.$route.query;
    this.orderType = orderType;

    this.userId = await SessionService.getUserId();
    // this.selectedOwnerWallet = await SessionService.getWallet().toUpperCase();

    window.ethereum.on("chainChanged", this.handleChainChanged);
  },

  // watch: {
  //   orderType() {
  //     console.log("orderType :::: ", this.orderType);
  //     this.init();
  //   },
  // },

  methods: {
    async onSelectProduct(product) {
      const joinedProduct = await OpenapiService.productJoinAsset(product.id);

      this.product = joinedProduct.data;

      this.artist_penname = product.artist_pen_name; // api return 값이 오타가 있다.
      this.balance = product.balance;

      let { royalties, description } = product;

      description = description.replace(/(<([^>]+)>)/gi, "");
      this.description = description;

      royalties = Number((royalties / 100).toFixed(2));
      this.royalties = royalties;

      this.selectedOwnerWallet = product.owner_wallet.toUpperCase();

      this.closeProductSelector();
    },

    closeProductSelector() {
      this.productChoice = false;
    },

    // onCancel() {
    //   this.$emit("onCancel", false);
    // },

    async onCreate() {
      //
      let ret = { status: false };
      const parulaParams = {};

      // form validation check
      const validation = await this.checkValidation();
      if (!validation) return;

      // session wallet - metamask wallet check
      let currentMetamaskAccount = await getCurrentMetamaskAccount();
      currentMetamaskAccount = currentMetamaskAccount.toUpperCase();

      if (currentMetamaskAccount.toUpperCase() !== this.selectedOwnerWallet) {
        notify({
          type: "error",
          text: "The login account and the metamask account are different.",
        });
        return;
      }

      // network check
      const currentMetamaskNetworkId = await getCurrentMetamaskNetworkId();
      console.log("currentMetamaskNetworkId", currentMetamaskNetworkId);

      if (currentMetamaskNetworkId !== this.product.blockchain_id) {
        notify({
          type: "error",
          text: "The artwork network and the metamask network are different.",
        });
        return;
      }

      // 유효성 검사 -> 메타마스크는 설치가 되어 있고 로그인 했으니 여기까지 왔겠지?, wallet이 있는지만 확인하자.
      const account = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (account.length < 1) {
        notify({ type: "error", text: "Can not found any connected wallet." });
        return;
      }

      this.account = account[0];

      const parula = new Parula(window.ethereum, {});

      let taValue = new BigNumber(this.fixedPrice);
      taValue = taValue.multipliedBy(this.quantity).toString();

      if (this.orderType === "normal") {
        parulaParams["maker"] = this.account;
        parulaParams["maTypeMV"] = this.product.asset_type_mv;
        parulaParams["maContractAddress"] = this.product.asset_address;
        parulaParams["maValue"] = Number(this.product.token_id);
        parulaParams["taTypeMV"] = this.selectedAsset.type_mv;
        parulaParams["taContractAddress"] = this.selectedAsset.address;
        parulaParams["taValue"] = taValue;
        parulaParams["quantity"] = Number(this.quantity);

        // const parulaParams = {
        //   maker: this.account,
        //   maTypeMV: this.product.asset_type_mv,
        //   maContractAddress: this.product.asset_address,
        //   maValue: Number(this.product.token_id),
        //   taTypeMV: this.selectedAsset.type_mv,
        //   taContractAddress: this.selectedAsset.address,
        //   taValue,
        //   quantity: Number(this.quantity),
        // };

        console.log("parulaNormalParams => ", parulaParams);
      } else if (this.orderType === "auction") {
        const beginAt = Math.floor(
          Date.parse(this.date[0].toISOString()) / 1000
        );

        // let beginAt = this.date[0].toISOString();
        // beginAt = Date.parse(beginAt);

        parulaParams["startDatetime"] = beginAt;
        // let endAt = this.date[1].toISOString();
        // endAt = Date.parse(endAt);
        // const endAt = Math.floor(Date.parse(this.date[1].toISOString()) / 1000);
        // parulaParams["endDatetime"] = endAt;

        parulaParams["maker"] = this.account;
        parulaParams["maTypeMV"] = this.product.asset_type_mv;
        parulaParams["maContractAddress"] = this.product.asset_address;
        parulaParams["maValue"] = Number(this.product.token_id);

        parulaParams["taTypeMV"] = this.selectedAsset.type_mv;
        parulaParams["taContractAddress"] = this.selectedAsset.address;
        parulaParams["taPriceBegin"] = this.beginPrice;
        parulaParams["taPriceEnd"] = this.buyNowPrice;

        console.log("parulaAuctionParams => ", parulaParams);
      } else {
        notify({ type: "error", text: "Error Occur" });
        return;
      }

      try {
        console.log(parulaParams);
        if (this.orderType === "normal") {
          ret = await parula.createSellOrder(parulaParams);
        } else if (this.orderType === "auction") {
          // if (this.useBuyNow) {
          //   ret =
          // } else {
          //   ret =
          // }

          ret = this.useBuyNow
            ? await parula.createAuction(parulaParams)
            : { status: true };
        }

        // console.log("[*] ", ret);
      } catch (error) {
        // 현재까지 catch한 error가 2가지임.
        if (error.code) {
          // 사인 안 하고 취소 눌렀을 시
          notify({ type: "error", text: error.message });
          throw new Error(`${error.code} error.message`);
        } else {
          // 사인하기 전 에러 발생 시
          notify({ type: "error", text: error });
          throw new Error(error);
        }
      }

      // DB 저장

      if (ret.status) {
        await this.saveNewOrderToDB(ret);
      } else {
        // error 2가지 형태로 발생됨.
        if (ret.err) {
          notify({ type: "error", text: ret.err });
          throw new Error(ret.err);
        } else {
          notify({ type: "error", text: "Error occur" });
          throw new Error();
        }
      }
    },

    saveNewOrderToDB(ret) {
      let orderType;
      orderType = this.orderType === "normal" ? "fixed" : "auction";

      this.orderState = this.orderState === 1 ? "onSales" : "pending";

      // TODO 예외처리
      let beginAt, endAt;

      if (this.orderType === "auction" && this.date.length !== 0) {
        // beginAt = this.date[0].toISOString();
        // endAt = this.date[1].toISOString();

        // beginAt = Date.parse(beginAt);
        // endAt = Date.parse(endAt);

        beginAt = Date.parse(this.date[0].toISOString());
        endAt = Date.parse(this.date[1].toISOString());
      }

      console.log("beginAt, endAt: ", beginAt, endAt);

      // balance 필요 !

      this.buyNowPrice = this.useBuyNow ? this.buyNowPrice : "";
      this.reservedPrice = this.useReserved ? this.reservedPrice : "";

      const params = {
        content_type: "product",
        content_id: this.product.id,
        title: this.product.name,
        description: this.description,
        image: this.product.image, // image,
        thumbnail: this.product.thumbnail,
        tag: this.hashtagList.toString(),
        chain_id: this.product.blockchain_id,
        asset_id: this.selectedAsset.id,
        order_rawdata: JSON.stringify(ret.data),
        order_signature: ret.signature,
        order_wallet: this.account,
        contract_id: "", // contract_id, 공백으로 보류
        order_type: orderType,
        price_fixed: weiValueToDB(this.fixedPrice, this.decimals),
        price_begin: weiValueToDB(this.beginPrice, this.decimals), // price_begin,
        immediate_buyable: this.useBuyNow, // immediate_buyable,
        immediate_price: weiValueToDB(this.buyNowPrice, this.decimals), // immediate_price,
        is_reserve: this.useReserved, // is_reserve,
        reserve_price: weiValueToDB(this.reservedPrice, this.decimals), // reserve_price,
        begin_at: beginAt, // beginAt,
        end_at: endAt, // endAt,
        has_tax: false, // has_tax,
        tax_ratio: "", // tax_ratio,
        has_donate: false, // has_donate,
        donate_ratio: "", // donate_ratio,
        fee_exchange: "", // fee_exchange,
        fee_creator: this.royalties * 100,
        order_state: this.orderState,
        seller: this.userId,
        main_content_id: this.product.main_content_id,
        sub_content_id: this.product.sub_content_id,
        main_content_type: this.product.main_content_type,
        sub_content_type: this.product.sub_content_type,
        quantity: Number(this.quantity),
        remain: Number(this.quantity), // (remain === this.quantity)
      };

      console.log("createOrder params => ", params);

      try {
        OpenapiAuth.createOrder(params).then(() => {
          this.$refs.ResultModal.open();
          // this.init();
        });
      } catch (error) {
        notify({ type: "error", text: error.message });
        throw new Error(error);
      }
    },

    changeAsset: function(e) {
      this.asset_id = e.id;
      this.selectedAsset = e;
      this.decimals = e.decimals;
    },

    init() {
      // this.orderType = "normal";
      this.$refs.InputHashtag.init();

      this.description = "";
      this.royalties = "";
      this.fixedPrice = "";

      this.selectedAsset = {};
      this.asset_id = "";
      this.orderState = 1;
      this.hashtagList = [];

      // erc1155
      this.quantity = 1;
      this.balance = 0; // (임시) product choice 했을 때 가져와야 함.

      // auction params
      this.beginPrice = "";
      this.buyNowPrice = "";
      this.reservedPrice = "";
      this.date = "";
      this.useBuyNow = false;
      this.useReserved = false;

      // common
      this.decimals = "";
      this.account = "";
      this.product = {};

      // modal control
      this.productChoice = false;
    },

    checkValidation() {
      // common

      if (!this.product.id) {
        notify({ type: "error", text: "The artwork doesn't selected" });
        return false;
      }

      if (this.hashtagList.length === 0) {
        notify({ type: "error", text: "The hashtag doesn't exist" });
        return false;
      }

      if (!this.selectedAsset.id) {
        notify({ type: "error", text: "The asset doesn't selected" });
        return false;
      }

      // normal
      if (this.orderType === "normal") {
        if (!this.fixedPrice) {
          notify({ type: "error", text: "The fixed price doesn't exist" });
          return false;
        }

        // 1155
        if (this.product.asset_type === "ERC1155") {
          if (!this.quantity) {
            notify({ type: "error", text: "The quantity doesn't exist" });
            return false;
          }
        }

        // auction
      } else if (this.orderType === "auction") {
        if (!this.beginPrice) {
          notify({ type: "error", text: "The begin price doesn't exist" });
          return false;
        }

        if (this.date.length === 0 && this.date.length !== 2) {
          notify({ type: "error", text: "The date doesn't exist or invalid" });
          return false;
        }

        if (this.useBuyNow) {
          if (!this.buyNowPrice) {
            notify({ type: "error", text: "The buy-now price doesn't exist" });
            return false;
          }
        }

        if (this.useReserved) {
          if (!this.reservedPrice) {
            notify({
              type: "error",
              text: "The reserved price doesn't exist",
            });
            return false;
          }
        }
      }

      return true;
    },

    handleChainChanged(_chainId) {
      this.selectedChainId = _chainId.replace("0x", "");
      console.log("handleChainChanged::", _chainId);
      this.init(); // 데이터 초기화
    },
  },
};
</script>

<style lang="scss">
.dp-custom-input {
  border-width: 1px;
  border-color: #262626;
  padding: 0.25rem 0.25rem 0.2rem 2.5rem;
  border-radius: 0;

  &:hover {
    border-width: 1px;
    border-color: #262626;
  }

  &::placeholder {
    color: #8a8a8a;
  }
}
</style>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";
/* cursor-default
focus:outline-none
border
parula__border-black
w-3/4
p-1 */

.input-design {
  border-width: 1px;
  border-color: #262626;
  width: 75%;
  padding: 0.25rem 0.5rem;
}

.form__adjustment {
  width: 80%;
  margin: 5rem auto 0 auto;
}

::placeholder {
  font-size: 1rem;
  // padding: 0.25rem;
  color: #8a8a8a;
}

.hover__bg--black:hover {
  background-color: #262626;
  color: white;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.6rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dddddd;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.2rem;
  width: 1.2rem;
  left: 0.2rem;
  bottom: 0.2rem;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #20A97F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #20A97F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1.4rem);
  -ms-transform: translateX(1.4rem);
  transform: translateX(1.4rem);
}

/* Rounded sliders */
.slider.round {
  border-radius: 1.5rem;
}

.slider.round:before {
  border-radius: 50%;
}

.button__green {
  font-weight: 600;
  @include button-radius(8rem, 2.5rem, #20A97F, $white);
}

.button__border__green {
  font-weight: 600;
  @include button-white-radius(8rem, 2.5rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}
</style>
