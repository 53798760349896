<template>
  <div>
    <div class="border parula__border-black bg-white">
      <section class="flex justify-between items-center px-5 py-1">
        <h1 class="font-semibold">Artwork List</h1>
        <svg
          @click="onclose"
          class="cursor-pointer"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512.001 512.001"
          style="enable-background: new 0 0 512.001 512.001"
          xml:space="preserve"
          height="16"
        >
          <g>
            <g>
              <path
                d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                  L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                  c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                  l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                  L284.286,256.002z"
              />
            </g>
          </g>
        </svg>
      </section>

      <hr class="parula__border-gray-soft" />

      <template v-if="saleableProducts.length === 0">
        <div class="flex justify-center items-center w-full h-full my-2">
          <span>No items</span>
        </div>
      </template>

      <section class="cursor-pointer">
        <div
          v-for="(product, index) in saleableProducts"
          :key="index"
          @click="onSelectProduct(product)"
        >
          <div
            class="
            flex
            items-center
            p-2
            text-xs
            md:text-sm
            lg:text-base
          "
          >
            <div class="flex items-center width">
              <div class="width__image">
                <content-viewer
                  v-if="product.thumbnail"
                  :filePath="product.thumbnail"
                />
              </div>

              <div class="flex flex-col justify-center width__description">
                <p class="font-semibold">{{ product.name }}</p>
                <div class="flex">
                  <img
                    :src="product.asset_thumbnail"
                    alt="symbol"
                    class="mr-1 w-4 h-4"
                  />
                  <p class="parula__text-gray">
                    {{ product.owner_wallet }}
                  </p>
                </div>
              </div>

              <p class="width__date flex justify-end">
                {{ product.publish_at.substring(0, 10) }}
              </p>
            </div>
          </div>
          <hr class="parula__border-gray-soft my-2" />
        </div>
      </section>

      <!-- pagination -->
      <section>
        <div class="flex justify-center mt-5 mb-3">
          <v-pagination
            v-model="page"
            :pages="pages"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="changePage"
          />
        </div>
      </section>
    </div>

    <notifications position="bottom right" />
  </div>
</template>

<script>
import OpenapiService from "@/services/openapi.service.js";
import SessionService from "@/services/session.service.js";
import ContentViewer from "@/components/ContentViewer.vue";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import { notify } from "@kyvg/vue3-notification";

export default {
  props: {
    // userData: {
    //   type: Object,
    //   required: true,
    // },

    orderType: {
      type: String,
      required: true,
    },
  },

  emits: ["selectedProduct", "closeProductSelector"],

  data() {
    return {
      searchParam: "",
      saleableProducts: [],

      ownerAddress: "",

      // paging
      page: 1, //
      pageSize: 5,
      pages: 1, // total page
    };
  },

  components: {
    ContentViewer,
    VPagination,
  },

  async created() {
    this.ownerAddress = await SessionService.getWallet();
    this.saleableProducts = await this.getSaleableProducts();
  },

  methods: {
    getSaleableProducts() {
      const auction = this.orderType === "auction" ? true : false;

      return new Promise((resolve) => {
        OpenapiService.getSaleableProducts(
          this.page,
          this.pageSize,
          "name",
          "",
          "registerable",
          "",
          "",
          this.ownerAddress,
          auction
        )
          .then((res) => {
            console.log("res ==> ", res);
            this.pages = Math.floor(res.data.total / this.pageSize) + 1;
            console.log("this.pages ==> ", this.pages);
            // const { items } = res.data;
            // console.log("이거다", items);

            resolve(res.data.items);
          })
          .catch((error) => {
            const msg = `Fail to get collection, (error: ${error}`;
            notify({ type: "error", text: msg });
          });
      });
    },

    async changePage(e) {
      this.page = e;
      this.saleableProducts = await this.getSaleableProducts();
    },

    // onSearch() {
    //   console.log("searchParam", this.searchParam);
    // },

    onSelectProduct(product) {
      this.$emit("selectedProduct", product);
    },

    onclose() {
      this.$emit("closeProductSelector");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_text.scss";

p {
  @extend %text__ellipsis;
}

.width {
  width: 100%;
  .width__image {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }

  .width__description {
    width: calc(70% - 4rem);
    height: 100%;
    margin-right: 0.5rem;
  }

  .width__date {
    width: 30%;
    height: 100%;
  }
}

.hover__black:hover {
  background-color: #262626;
  color: white;
}
</style>
